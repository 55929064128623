$white: #fff;
$lightestgray: #E8E8E8;
$lightergray: #efefef;
$lightgray: #ccc;
$lightermediumgrey: #999;
$lightmediumgrey: #666;
$mediumgrey: #555555;
$darkgrey: #333;

$denimwhite: #e0e5f2;
$greenwhite: #F7F7F4;
$paledenim: #ACBDDB;
$paledenim2: #ECF4F9;
$mediumdenim: #5A8BC8;
$aqua: #55ADE0;
$greendenim: #0C70A7;
$denim: #3C6FB3;
$brightdenim: #1D5DA8;
$deepblue: rgba(36,78,145,1);

$tanwhite: #f5f0eb;

$deepred: #990000;


$colors: $white, $lightergray, $lightestgray, $lightgray, $lightmediumgrey, $mediumgrey, $darkgrey, $greenwhite, $paledenim2, $denimwhite, $paledenim, $mediumdenim, $denim, $brightdenim, $deepblue, $aqua, $greendenim, $tanwhite, $deepred;

.swatches {
  @for $i from 1 through length($colors) {
    li:nth-child(#{length($colors)}n+#{$i}) {
      display: inline-block;
      height: 200px;
      width: 50px;
      margin: 0;
      padding: 0;
      background: nth($colors, $i);
    }
  }
}