/* ---------- Base typography ---------- */


body {
  font-size: medium;                /* IE renders "small" at 12px */ 
  font-family: 'Open Sans', serif;
}

html > body {
  font-size: large;                /* MOSe render "medium" at 12px */
}

/* ---------- Typographical Hierarchy ---------- */

$th1: 3em;  /* 36px / 12px */
$th2: 2em;  /* 24px / 12px */
$th3: 1.75em;  /* 21px / 12px */
$th4: 1.34em; /*1.5em;  18px / 12px */
$th5: 1.1em; /* 1.2em */
$th6: 1em;  /* 1.1.em */

/* ---------- Headers ---------- */

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', Helvetica, Arial, sans-serif;
  font-weight: 900;
  margin-bottom: .5em;
}

h1 {
  font-size: $th1;  /* 36px / 12px */
}

h2 {
  font-size: $th2;  /* 24px / 12px */
}

h3 {
  font-size: $th3;  /* 21px / 12px */
}

h4 {
  font-size: $th4; /*1.5em;  18px / 12px */
}

h5 {
  font-size: $th5; /* 1.34em;  16px / 12px */
}

h6 {
  font-size: $th6; /* 14px / 12px */
}

/* ---------- Body Elements ----------- */

p {
  line-height: 1.5em;
  margin-bottom: 1em;
}

blockquote {
  font-family: 'Playfair Display';
  font-size: $th1;
  font-weight: 600;
  font-style: italic;
  color: $lightermediumgrey;
  margin-bottom: 1em;
}


/* ---------- Anchor Tags ----------- */

a {
  text-decoration: none;
  color: #004DA8;

  &:visited {
    text-decoration: none;
    color: #3A83A7;
  }
  &:hover {
    text-decoration: underline;
    color: #0B61A4;
  }
}

.broken-link a {
  background-image: url('images/broken-link-blue.png');
  background-position: right .2em;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  padding-right: 16px;
  
  &:hover:after {
    content: "Unfortunately, the site owner no longer maintains this site.";
    display: block;
    font-size: .5em;
    background: none;
    position: absolute;
    text-decoration: none;
    line-height: 1em;
  }
  &.no-hover:hover:after {
    content:"";
  }
}