/* ---------- Browser normalization ---------- */

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none outside none;
}

ol {
  list-style-type: decimal;
}

caption, th {
  text-align: left;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
}

em, i {
  font-style: italic;
}

strong, bold {
  font-weight: bold;
}