@import "normalization";
@import "colors";
@import "typography";
@import "triangle";


/* ----------- Main ---------- */

body {
  position: relative;
  margin: 0;
  padding: 0;
  top: 0;
  background-color: $white;
  color: $darkgrey;
}

#wrapper {
}

#branding,
#content {
  position: relative;
  margin: 0 auto;
  padding: 0 1em;
  /* IE Fix to setup later  width: expression(document.body.clientWidth < 742? "740px" : document.body.clientWidth > 1202? "1200px" : "auto"); */
}

.section {
  margin-top: 3em;
}



/* ---------- Branding ---------- */

.header {
  width: 100%;
  background: $greendenim url('../images/verbisthewordBanner-01.png') repeat-x center bottom;
  border-bottom: 1px solid $brightdenim;
  color: $white;
}

#branding {
  h1,
  a {
    color: $white;
    border: 1px solid transparent;
  }
  h1 {
    float: left;
    margin-top: 0;
    margin-bottom: .2em;
    margin-right: .4em;
    padding-top: .2em;
    
    a:hover {
      border: 1px solid transparent;
      border-bottom: 1px solid $white;
      background-color: transparent;
    }
  }
  a:hover {
    text-decoration: none;
    border: 1px solid $paledenim;
    background-color: $denim;
  }
  ul {
    float: left;
    margin-top: 0;
    padding-top: 2em;
    font-size: 1.2em;
    text-transform: lowercase;
  }
  li {
    float: left;
    margin-right: .4em;

    a {
      display: block;
      padding: 0.2em 0.4em 0.3em;
      border-bottom: 1px solid transparent;
      -webkit-border-radius: .3em;
      -moz-border-radius: .3em;
      border-radius: .3em;
    }
    &.current a {
      background-color: $mediumdenim;
      border: 1px solid $brightdenim;
    }
  }
  a.external-link {
    background-image: url('../images/arrow_full_upperright_16-mediumBlue.png');
    background-position: right .2em;
    background-repeat: no-repeat;
    padding-right: 16px;
  }
}

body[data-active='writing'] a.writing-link,
body[data-active='speaking'] a.speaking-link,
body[data-active='sketchnotes'] a.sketchnotes-link,
body[data-active='contact'] a.contact-link {
  background-color: $mediumdenim;
  border: 1px solid $brightdenim;
}

/* ---------- Content ---------- */

#content {
  margin-top: 2em;
  margin-bottom: 2em;
  clear: left;
  
  ul,
  ol {
    margin: 1em;
    line-height: 1.5em;
    list-style: disc inside none;
  }
}

.col30 {
  float: left;
  width: 100%;
}


/* ---------- Infobox ---------- */
.infobox {
  float: right;
  width: 210px;
  margin: 0;

  .portrait {
    width: 160px;
    margin: 10px 1.8em .5em;
  }
}


/* ---------- Sketchnotes ---------- */  

.resources {
  float: left;
  width: 65%;
}
.image-list {
  li {
    float: left;
    width: 31%;
    height: 19em;
    margin-right: 2%;
    list-style-type: none;
    p {
      float: left;
    }
  }
}
.sketchnote.thumbnail {
  float: left;
  width: 100%;
  margin-bottom: .5em;
  border: 1px solid $lightergray;
  box-shadow: 2px 2px 4px $lightestgray;
}

/* ---------- Photos ---------- */  
.photo,
.photo.feature {
  width: 100%;
}
.text.feature {
  font-size: 1.3em;
}


/* ---------- Contact Form ---------- */
form {
  width: 100%;
}

label, 
input, 
textarea, 
button,
select {
  box-sizing: border-box;
  display: block;
  width: 100%;
  min-width: 300px;
  margin-bottom: 1em;
  padding: .3em;
}

input, 
textarea, 
button,
select {
  border-color: $lightgray;
  border-style: solid;
  border-width: 1px;

  &:hover {
    background-color: $greenwhite;
  }
}


label {
  clear: left;
  margin-bottom: 0.2em;
  padding: .5em 0;
  width: 105px;
}

textarea {
  height: 130px;
  margin-bottom: 1.5em;
}

input[type="submit"], 
button {
  clear: left;
  padding: 1em;
  color: $white;
  background-color: $deepblue;
  border: 1px solid $aqua;
}

input[type="submit"]:hover, 
button:hover {
  background-color: $greendenim;
  border: 1px solid $aqua;
}

/* ---------- Book Covers ---------- */

.list li {
  margin: 0 1em 0 2em;
  list-style-position: outside;
}

.books,
.online {
  li {
    float: left;
    width: 46%;
    margin-right: 1.2em;
    list-style-type: none;
  }
}

.online li {
  width: 100%;
  margin-bottom: 1em;

  p:last-child {
    margin-bottom: 0;
    font-size: .9em;
  }
  
  p:last-child {
    margin-top: -10px;
  }
}

.highlight {
  font-style: italic;
  font-weight: 600;
}

.book {
  float: left;
  width: 6em;
  margin-right: 1em;
  margin-bottom: .5em;
  border: 1px solid $lightergray;
  box-shadow: 2px 2px 4px $lightestgray;
}

.title {
  font-style: italic;
}

.context {
  margin-left: 8em;
  font-size: .9em;
}


/* ---------- Error Message ---------- */

.errorMsg {
  width: 290px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid $deepred;
  background-color: $tanwhite;
}


/* ---------- Resume ---------- */
  
dt {
  font-weight: bold;
  margin-top: 1em;
}
    
h3.section {
  font-size: 1.1em;
  font-weight: normal;
  text-transform: uppercase;
  color: $mediumgrey;
  margin-bottom: .5em;
}
    
dd,
.experience li {
  line-height: 1.3em;
  list-style-type: disc;
  list-style-position: outside;
}

.experience dd {
  display: list-item;
  margin-left: 1em;
}

.experience dd.employer {
  display: block;
  margin-left: 0;
  margin-bottom: .1em;
}

dl.experience li {
  margin-left: 2em;
}

span.plainText {
  font-weight: normal;
}

.footnote {
  margin-top: 4em; 
  color: $lightmediumgrey;
}

/* ---------- Portfolio ----------*/

.thumb {
  float: left;
  width: 200px;
  margin: .5em 1em 1em 0;
  border: 1px solid $lightgray;
  border-top: 1px solid $lightergray;
  border-left: 1px solid $lightergray;
}


/* ---------- Blog Index ----------*/

article,
section,
#content .latest-post,
#content .recent-posts {
  & > ol {
    margin: 0;
    margin-top: 40px;

    & > li {
      clear: left;
      list-style-type: none;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    line-height: 1;
  }
  h1 {
    font-size: $th2;
  }
  h2 {
    font-size: $th3;
  }
  h3 {
    font-size: $th4;
  }
  h4 {
    font-size: $th5;
  }
  h5 {
    font-size: $th6;
  }
  h6 {
    font-size: $th6;
  }
  h6 {
    font-size: $th6;
  }
}
article,
section {
  p:nth-of-type(2) {
    float: left;
    margin: 0 1.5em 1.5em 0;
  }
  ol li {
    list-style-type: decimal;
  }
}

.h-entry {
  margin-bottom: 3em;
  overflow: hidden;
}

.about {
  margin-top: 6em;
}





/* ---------- Misc ----------*/

/* self-clearing floats */
.group:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}


/* ---------- Media Queries ---------- */
@media screen and (min-width: 500px) {
  .col30 {
    width: 48%;
    margin-right: 2%;
  }
}

@media screen and (min-width: 690px) {
  #branding,
  #content {
    max-width: 46.6666667em;  /* 620px / 12px = 51.6666667em */ /* Changed to `46em because just looks nicer, and also works in Safari because I am lazy; I liked 45em better! */
  }
  .vimeo {
    width:100%;
    height: 380px;
  }
  #content-wrapper-home {
    width: 30%;
    margin-right: 2%;
    float: left;
  }
  .col30 {
    width: 30%;
  }
}

@media screen and (max-width: 690px) {
  #content-wrapper-home,
  .infobox {
    float: none;
    width: 100%;
  }
  .twitterTitle {
    display: block;
  }
  .infobox .portrait {
    margin-top: 0;
  }
  
  .triangle-border.top:before {
    right: auto;
    left: 20px;
  }
  .triangle-border.top:after {
    right:Ar auto;
    left: 22px;
  }
  ul#twitter_update_list li > a {
    display: inline;
    text-align: left;
  }
  .flickr_badge_image:nth-child(2n) {
    clear: none;
  }
  label, input, textarea, button, #recaptcha_widget_div {
    clear: left;
  }
  label {
    padding: 0;
  }
  .vimeo {
    width:100%;
    height: 300px;
  }
  .books li {
    width: 100%;
  }
}

@media screen and (max-width: 750px) { /*previously 545px, 485px*/
  #branding h1 {
    float:none;
    margin-bottom: .1em;
  }
  
  #branding ul {
    float: right;
    margin-bottom: .5em;
    padding-top: 0;
  }
  
  .thumb {
    float: none;
    width: 100%;
  }
  .flickr_badge_image:nth-child(3n+2) {
    clear: left;
  }
  
  .resources,
  .flickr-feature {
    width: 47%;
  }

  .image-list li {
    width: 45%;
    height: 21em;
  }

}

@media screen and (max-width: 470px) {
  .vimeo {
    width:100%;
    height: 200px;
  }
  .image-list li {
    width: 100%;
    margin-right: 0;
  }
}

@media screen and (max-width: 450px) {
  #branding h1 {
    font-size: 2em;
  }
  #branding ul {
    font-size: 1em;
  }
}

@media screen and (max-width: 385px) {
  #branding ul {
    float: left;
    margin-left: 0;
    
    li:first-child {
      margin-right: 0
    }
  }
  .resources,
  .flickr-feature {
    float: left;
    width: 100%;
  }
}
